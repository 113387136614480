import VideoModal from 'components/modal/video';

import './styles/video-media-object.scss';

type VideoMediaObjectProps = {
  copy: string,
  image_desktop: {
    source_url: string,
  },
  launch_video_text: string,
  sub_title: string,
  title: string,
  video_id: string,
}

const VideoMediaObject = ({
  copy,
  image_desktop,
  launch_video_text,
  sub_title,
  title,
  video_id,
}: VideoMediaObjectProps) => {

  return (
    <section className="video-media-object-section section">
      <div className="container">
        <div className="columns">
          <div className="column video-text">
            <h2 className="subheadline">{ sub_title }</h2>
            <h3 className="headline">{ title }</h3>
            <p>{ copy }</p>
            <VideoModal
              hashedId={ video_id }
              text={ launch_video_text }
              title={ launch_video_text }
              className="link"
              autoPlay
            />
          </div>
          <div className="column video-image">
            <VideoModal
              hashedId={ video_id }
              autoPlay
              noRender
            >
              <img src={ image_desktop.source_url } />
            </VideoModal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoMediaObject;