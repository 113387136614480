import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import CustomerStories from 'components/content-blocks/customer-stories';
import ImageGroup from 'components/content-blocks/image-group';
import MediaObject from 'components/content-blocks/media-object';
import VideoMediaObject from 'components/content-blocks/video-media-object';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/continue-trial.scss';

const ContinueTrial = () => {
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: {slug: {eq: "continue-trial"}}) {
      edges {
        node {
          wordpress_id
          yoast_head_json {
            title
            description
            canonical
            og_title
            og_description
            og_image {
              url {
                source_url
              }
            }
            og_url
            og_type
            twitter_title
            twitter_description
            twitter_image {
              source_url
            }
          }
          acf {
            basic_title_subtitle_copy_image {
              copy
            }
            customer_stories {
              card_section_title
              section {
                copy
                sub_title
                title
              }
              card_groups {
                title
                card {
                  card_link
                  card_link_title
                  image {
                    source_url
                  }
                  industry
                  sub_title
                  title
                }
              }
            }
            media_object {
              title
              sub_title
              body
              desktop_image {
                source_url
              }
              mobile_image {
                source_url
              }
              button_text
              schedule_demo
            }
            hero {
              title
              sub_title
              signup {
                hide_signup_component
              }
              background_desktop_image {
                source_url
              }
              background_mobile_image {
                source_url
              }
              copy
              link {
                url
                text
              }
            }
            image_group {
              title
              link {
                text
                url
              }
              images {
                image {
                  source_url
                }
              }
            }
            video_media_object {
              copy
              image_desktop {
                source_url
              }
              launch_video_text
              sub_title
              title
              video_id
            }
          }
        }
      }
    }
  }  
  `);
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const customerStories = pageContext.customer_stories;
  const mediaObject = pageContext.media_object;
  const imageGroup = pageContext.image_group;
  const videoMediaObject = pageContext.video_media_object;
  const couponText = pageContext.basic_title_subtitle_copy_image;

  return (
    <Layout>
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="lp-continue-trial-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } button />
        <ImageGroup className="" { ...imageGroup[0] } />
        <VideoMediaObject { ...videoMediaObject } />
        <MediaObject { ...mediaObject[0] } className="reverse" schedule_demo_text={ mediaObject[0].button_text } />
        <CustomerStories { ...customerStories } />
        <section className="coupon-section">
          <p className="copy">{ couponText.copy }</p>
        </section>
      </div>
    </Layout>
  );
};

export default ContinueTrial;